<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    +textarea-validation('data.body.comment', 'comment', '["required", "alphaENText"]')
  div.col-12
    v-btn(color="error" @click="setReject") {{$t('setReject')}}
</template>
<script>
import { RejectForm } from '@/mixins/validationRules'
import { mapActions } from 'vuex'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  data () {
    return {
      data: {
        body: {
          comment: null,
          additional_info: {}
        },
        status_document: STATUSES_DOCUMENT_CONSTANTS.rejectedPowersOfAttorney
      }
    }
  },
  validations () { return RejectForm() },
  methods: {
    ...mapActions(['setRejectPowersOfAttorneyById']),
    async setReject () {
      if (this.$v.$invalid) return this.$v.$touch()
      const { id } = this.$route.params
      this.data.id = id

      this.$swal({
        title: this.$t('warning'),
        text: this.$t('rejectingConfirmation'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async (confirmation) => {
        if (confirmation) {
          const response = await this.setRejectPowersOfAttorneyById(this.data)
          if (response) {
            this.$parent.getData()
          }
          this.$v.$reset()
        }
      })
    }
  }
}
</script>
